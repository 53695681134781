<template>
  <div class="pt-24">
    <div class="max-w-2xl rounded border border-primary mx-auto px-2 pb-2">
      <div
        v-for="recipe in recipes" :key="recipe.id"
        class="flex items-center rounded-lg border border-primary mt-2 px-2 py-1"
      >
        <div>{{ recipe.name }}</div>
        <div class="ml-auto flex items-center">
          <label for="">Published</label>
          <input type="checkbox" class="ml-2" v-model="recipe.published" disabled>
        </div>
        <router-link
          :to="`/recept/${recipe.slug}`"
          class="bg-primary text-white rounded-lg p-1 text-center ml-4"
        >Preview</router-link>
        <router-link
          :to="`/admin/recipes/${recipe.id}`"
          class="bg-primary text-white rounded-lg p-1 text-center ml-4"
        >edit</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import http from '../../http'

export default {
  data () {
    return {
      recipes: []
    }
  },
  mounted () {
    this.getRecipes()
  },
  methods: {
    async getRecipes () {
      const res = await http.get('/api/recipes')

      this.recipes = res.data
    }
  }
}
</script>
